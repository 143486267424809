@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: 'Oswald', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #0c0a0a;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.gray {
    background-color: #0d0b26 !important;
}

.widget-title {
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e1e7ec;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}